"use client";

import { Amplify } from "aws-amplify";

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId:
          process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID ??
          "eu-central-1_PvkVvvN4Z",
        userPoolClientId:
          process.env.NEXT_PUBLIC_COGNITO_USER_POOL_CLIENT_ID ??
          "19fvteu7hjvfamusqs1cpu57fv",
      },
    },
  },
  { ssr: true },
);

export default function ConfigureAmplifyClientSide() {
  return null;
}
